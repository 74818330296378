<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="assessment-plan"
  >
    <div>
      <h2 class="mg-y-2">
        <span
          v-if="user.role != 50"
          class="cr-pointer"
          @click="$router.push(`/matching/assessment-plan`)"
        >
          Assessment Plan
        </span>
        <span v-else> Assessment Plan </span>
        >
        <span class="color-light-blue">View Assessment Plan</span>
      </h2>
      <div :gutter="15" class="is-flex ai-center js-end mg-t-4">
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitPrint()"
          :loading="loadingPrint"
          >Print</el-button
        >
      </div>
    </div>

    <div class="group-view-plan" v-if="!loading">
      <PreviewAssessmentPlan :form="form" :viewOnly="true" />
    </div>
    <div class="js-start is-flex mg-t-4" v-if="user.role == 50">
      <el-button round @click="$router.go(-1)">
        <span class="font-14"> Back </span>
      </el-button>
    </div>
    <Html2pdf
      v-if="!loading"
      :generatePdf="generatePdf"
      @onProgress="onProgress"
      :nameFile="form.planName"
    >
      <PreviewAssessmentPlan :form="form" />
    </Html2pdf>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import Html2pdf from "@/components/pdf/Html2pdf";
import PreviewAssessmentPlan from "@/components/assessment-plan/PreviewAssessmentPlan";

export default {
  components: {
    Html2pdf,
    PreviewAssessmentPlan,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      typeMaster: (state) => state.typeMaster,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
  mounted() {
    this.getAssessmentPlanById();
  },
  data() {
    return {
      loading: false,
      loadingBtn: false,
      loadingPrint: false,
      fetchData: [],
      form: {},
      detailPlan: [],
      generatePdf: false,
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
      progress: 0,
    };
  },
  methods: {
    async getAssessmentPlanById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"]

      HTTP.get(`matching/assessment/` + this.$route.params.id)
        .then(async (res) => {
          if (res.data.success) {
            this.form = res.data.obj;
            this.form.matching = await this.form.matching.map((row) => {
              let setCat = row.companyType.catNo.join(", ");
              return {
                ...row,
                dateTime:
                  this.formatDate(row.date) +
                  " " +
                  row.timeStart +
                  " - " +
                  row.timeEnd,
                companyType: {
                  ...row.companyType,
                  catNo: setCat,
                },
              };
            });
            this.form.isOffSite = this.form.matching.some(
              (item) => item.typeOfVisit === 1
            );
            this.form.isOnSite = this.form.matching.some(
              (item) => item.typeOfVisit === 2
            );
            this.form.isRemote = this.form.matching.some(
              (item) => item.typeOfVisit === 3
            );

            if (this.form.logoPlan) {
              let setUrl =
                this.imageUrl +
                "assessment-plan/" +
                this.form._id +
                "/" +
                this.form.logoPlan;
              this.form.logoPlan = setUrl;
            }
          } else {
            this.$router.push(`/matching/assessment-plan`);
            this.$notify({
              title: "ไม่พบ Assessment Plan !",
              type: "error",
              customClass: "error",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitPrint() {
      this.loadingPrint = true;
      setTimeout(() => {
        this.generatePdf = true;
      }, 100);
    },
    onProgress(progress) {
      this.progress = progress;
      if (this.progress == 100) {
        this.loadingPrint = false;
        this.generatePdf = false;
      }
    },
  },
};
</script>
