<template>
  <div class="preview-assessment">
    <el-form label-position="top" :model="form" ref="ruleForm" status-icon>
      <el-row :gutter="15">
        <el-col :span="24" class="box-logo-company">
          <img
            alt="logo"
            :src="form.logoPlan"
            class="mw-100"
            v-if="form.logoPlan"
          />
          <span> {{ form.planName }} </span>
        </el-col>
        <el-col :span="24" class="mg-y-2"> <hr /> </el-col>
        <el-col :span="24" class="is-flex ai-center js-end mg-b-2 flex-wrap">
          <span class="mg-r-1">
            Date :
            {{ form.date }}
          </span>
          <el-checkbox-group>
            <label class="el-checkbox">
              <input
                type="checkbox"
                :value="1"
                :checked="form.isOffSite ? true : false"
              />
              <label for="vehicle1">Off-site</label>
            </label>
            <label class="el-checkbox">
              <input
                type="checkbox"
                :value="2"
                :checked="form.isOnSite ? true : false"
              />
              <label for="vehicle1">On-site</label>
            </label>
            <label class="el-checkbox">
              <input
                type="checkbox"
                :value="3"
                :checked="form.isRemote ? true : false"
              />
              <label for="vehicle1">Remote</label>
            </label>
          </el-checkbox-group>
        </el-col>
        <el-col :span="24" class="card-preview">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Client name">
                {{ form.companyName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Standard">
                <div
                  class="span-custom"
                  v-html="
                    setStandardView(
                      'standard',
                      form.matching,
                      optionsStandardFood,
                      optionsStandardIsoOne,
                      optionsStandardIsoTwo
                    )
                  "
                ></div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-row :gutter="15" type="flex" class="flex-wrap">
                <el-col :span="12">
                  <el-form-item label="Address">
                    {{ form.address }}
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Scope">
                    <div
                      class="span-custom"
                      v-html="setStandardView('scope', form.matching)"
                    ></div>
                    <!-- <span v-for="(item, index) in form.matching" :key="index">
                      {{ item.companyType.scoped }}
                      {{ index != form.matching.length - 1 ? "/ " : "" }}
                    </span> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Contact Person">
                {{ form.contactPerson }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Tel."> {{ form.phone }} </el-form-item>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-for="(item, index) in form.matching"
            :key="index"
          >
            <el-col :span="12">
              <el-form-item :label="`Auditor ${index + 1}`">
                {{ item.auditor.fullName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Tel.">
                {{ item.auditor.phone }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" class="card-preview">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Confirm by customer">
                {{ form.clientSign }}</el-form-item
              >
            </el-col>
            <el-col :span="12">
              <el-form-item label="Review by Lead auditor">
                {{ form.leadAuditorSign }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" class="card-preview">
          <p class="font-weight-bold">
            หมายเหตุ : วัตถุประสงค์ในการตรวจประเมิน
          </p>
          <span class="">
            -
            เพื่อประเมินและแสดงสอดคล้องระบบบริหารจัดการว่าเป็นไปตามมาตรฐานที่รับรอง
            และหลักเกณฑ์ในการตรวจประเมิน รวมถึงกฎหมายและมาตรฐาน
            หรือสัญญาที่เกี่ยวข้อง
          </span>
          <br />
          <br />
          <span class="">
            - เพื่อประเมินความมีประสิทธิภาพของระบบบริหารจัดการ
            เพื่อให้มั่นใจถึงการที่สถานประกอบการมีการดำเนินระบบให้สอดคล้องกับ
            เป้าหมายที่ตั้งไว้อย่างต่อเนื่อง
          </span>
          <br />
          <br />
          <span class="">
            - เพื่อดูโอกาสในการปรับปรุงระบบบริหารจัดการเมื่อมีโอกาส
          </span>
        </el-col>
        <el-col :span="24" class="befor-table">
          <TableAssessmentImport :data="form.dataImport" :viewOnly="viewOnly" />
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TableAssessmentImport from "@/components/table/TableAssessmentImport";
export default {
  components: {
    TableAssessmentImport,
  },
  props: {
    form: {
      type: Object,
      default: {},
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      typeMaster: (state) => state.typeMaster,
      optionsStandardFood: (state) => state.optionAll.optionsStandardFood,
      optionsCompanyIso: (state) => state.optionAll.optionsCompanyIso,
      optionsStandardIsoOne: (state) => state.optionAll.optionsStandardIsoOne,
      optionsStandardIsoTwo: (state) => state.optionAll.optionsStandardIsoTwo,
    }),
  },
};
</script>
