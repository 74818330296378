<template>
  <div>
    <div style="overflow-x: auto">
      <table style="width: 100%" class="table-import" v-if="viewOnly">
        <thead>
          <tr>
            <th>Date</th>
            <th>Start Time</th>
            <th>Process</th>
            <th>Person</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="data.length == 0">
            <td colspan="100%">ไม่พบข้อมูล</td>
          </tr>
          <tr v-for="(row, index) in data" :key="index">
            <td>
              <span>
                {{ formatDateCustom(row.date) }}
              </span>
            </td>
            <td>
              <span>
                {{ row.startTime }}
              </span>
            </td>
            <td class="text-left">
              <span>
                {{ row.process }}
              </span>
            </td>
            <td>
              <span>
                {{ row.person }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <template v-else>
        <div v-for="numberPage in forLoop" :key="numberPage">
          <p class="html2pdf__page-break"></p>
          <table style="width: 100%" class="table-import">
            <thead>
              <tr>
                <th>Date</th>
                <th>Start Time</th>
                <th>Process</th>
                <th>Person</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="data.length == 0">
                <td colspan="100%">ไม่พบข้อมูล</td>
              </tr>
              <tr v-for="endPage in limit" :key="endPage">
                <td
                  v-if="
                    data[numberPage * limit - limit + endPage - 1] != undefined
                  "
                >
                  <span>
                    {{
                      returnDate(data[numberPage * limit - limit + endPage - 1])
                    }}
                  </span>
                </td>
                <td
                  v-if="
                    data[numberPage * limit - limit + endPage - 1] != undefined
                  "
                >
                  <span>
                    {{
                      data[numberPage * limit - limit + endPage - 1].startTime
                    }}
                  </span>
                </td>
                <td
                  class="text-left"
                  v-if="
                    data[numberPage * limit - limit + endPage - 1] != undefined
                  "
                >
                  <span>
                    {{ data[numberPage * limit - limit + endPage - 1].process }}
                  </span>
                </td>
                <td
                  v-if="
                    data[numberPage * limit - limit + endPage - 1] != undefined
                  "
                >
                  <span>
                    {{ data[numberPage * limit - limit + endPage - 1].person }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      default: Array,
      return: [],
    },
    viewOnly: {
      type: Boolean,
      return: false,
    },
  },
  data() {
    return {
      forLoop: 0,
      totalInt: 0,
      limit: 26,
      startArr: 0,
      endArr: 0,
    };
  },
  mounted() {
    this.totalInt = parseInt(this.data.length / this.limit);
    if (this.totalInt != this.data.length / this.limit) {
      this.forLoop = this.totalInt + 1;
    }
  },
  watch: {
    data() {
      this.totalInt = 0;
      this.forLoop = 0;
      this.totalInt = parseInt(this.data.length / this.limit);
      if (this.totalInt != this.data.length / this.limit) {
        this.forLoop = this.totalInt + 1;
      }
    },
  },
  methods: {
    formatDateCustom(date) {
      let dates = "";
      if (date != undefined && date != "") {
        dates = moment(moment(date)).format("L");
      }
      return dates;
    },
    endTime(page, limit) {
      let skip = page == 1 ? limit : page * limit - limit;

      if (page > this.totalInt) {
        if (this.totalInt == 0) {
          skip = this.data.length;
        } else {
          skip = this.data.length - skip;
        }
      }
      return skip;
    },
    returnDate(data) {
      let date = "";
      if (data.date != "") {
        date = moment(moment(data.date)).format("L");
      }
      return date;
    },
  },
};
</script>
